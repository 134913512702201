import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '*',
    redirect: { name: 'home' }
  },
  {
    path: '/booking/:id',
    name: 'booking',
    component: () => import('../views/Booking.vue'),
    props(route) {
      //make sure id prop is integer
      const props = { ...route.params }
      props.id = +props.id
      return props
    },
  },
  {
    path: '/event-edit/:id',
    name: 'event-edit',
    component: () => import('../views/EventEdit.vue'),
    props(route) {
      //make sure id prop is integer
      const props = { ...route.params }
      props.id = +props.id
      return props
    },
  },
  {
    path: '/event-group-edit/:id',
    name: 'event-group-edit',
    component: () => import('../views/EventGroupEdit.vue'),
    props(route) {
      //make sure id prop is integer
      const props = { ...route.params }
      props.id = +props.id
      return props
    },
  },
  {
    path: '/event-bookings/:id',
    name: 'event-bookings',
    component: () => import('../views/EventBookings.vue'),
    props(route) {
      //make sure id prop is integer
      const props = { ...route.params }
      props.id = +props.id
      return props
    },
  },
  {
    path: '/saloon-edit/:id',
    name: 'saloon-edit',
    component: () => import('../views/SaloonEdit.vue'),
    props(route) {
      //make sure id prop is integer
      const props = { ...route.params }
      props.id = +props.id
      return props
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log('router beforeEach')
  if (store.state.firstRouterHasLoaded) {
    //this is not initial router load
    console.log('to', to)
    if (!store.state.agent && to.name != 'home') {
      //if we have no agent, and are not at 'home', go to home (in order to present error message)
      next({ name: 'home' })
    }
    next()
  } else {
    //set firstRouterHasLoaded to true so we do not consider this again
    store.dispatch('setFirstRouterHasLoaded')
    //get agent from query param or local storage
    let agent = (to.query.agent) ? to.query.agent : localStorage.getItem('agent')
    if (agent) {
      //agent existed, store in vuex
      store.dispatch('setAgent', agent)
    } else {
      //we have no agent and are not able to serve any data
      next({ name: 'home' })
    }
    next()
  }
})

export default router
