<template>
  <v-app>
    <v-main>
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
//import axios from 'axios'
//import { mapGetters } from 'vuex'
//import { snackBarBus } from './main'
//import { generalMixin } from './mixins/general'
//import { urlMixin } from './mixins/url'
//import { authMixin } from './mixins/auth'

export default {
  name: 'App',
  data: () => ({
  }),
//  mixins: [generalMixin, urlMixin, authMixin],
  computed: {
//    ...mapGetters(['isLoggedIn', 'isAdmin', 'agentType', 'agentName', 'userName', 'loggedInData', 'hasMultipleAgentAccess', 'traceOutput', 'showWelcomeAlert', 'version', 'isLoggedInAs', 'isDeveloper', 'getHomeRoute', 'access_token']),
  },
  methods: {
  },
  created() {
  },
}
</script>

<style>
/* fix no scroll for calendar */
html {
  overflow-y: unset !important;
}
/* fix no scroll for iframe */
.v-application {
  line-height: unset !important;
}
.no-wrap {
  white-space: nowrap
}
.xSimpleTable.list td {
  padding:8px 16px !important;
  height: auto !important;
  vertical-align: top !important;
}
.xSimpleTable.xClick td {
  cursor: pointer;
}
div.v-data-table th
,.xCardTitle
{
  background-color: #77818633 !important;
  white-space: nowrap !important;
}
.xDenseToggle button {
  min-width: 30px !important;
}
.xClickRowTable tbody > tr
,.cursor
{
  cursor: pointer !important;
}
.xSimpleTable.width-auto > div > table {
  width: auto !important;
}
.xSimpleTable > div {
  padding-top: 4px;
  overflow-y: visible !important; /*in order to get the ring in "År" visible when label on text-field inside top table cell */
  overflow-x: visible !important; /*in order to get the ring in "År" visible when label on text-field inside top table cell */
}
.xListButton {
  cursor: pointer;
  padding:4px;
}
.xListButton:hover {
  background-color: rgba(0,0,0,0.1);
}
.alert {
  color:crimson !important;
}
</style>
