import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import axios from 'axios'
import VueHtmlToPaper from 'vue-html-to-paper'

const options = {
  name: '_blank',
  specs: ['fullscreen=no', 'titlebar=no', 'scrollbars=no'],
  autoClose: false,//does not appear to work (i had to install v 1.3.1 due to a bug in latest release)
  styles: [
    'https://cdn.webomaten.se/booking/print.css?z=11',
    'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900',
//    'https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css'
  ]
};

Vue.use(VueHtmlToPaper, options)

Vue.config.productionTip = false

//to be able to display snackbar alerts all over the app
export const snackBarBus = new Vue()

//Vue.config.productionTip = false
axios.defaults.baseURL = 'https://booking-api.webomaten.se/rest'
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.headers.put['Content-Type'] = 'application/json;charset=UTF-8'

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
