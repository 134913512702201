export const urlMixin = {
  methods: {
    url_composeApiUrl(path, queryStringData_ar = []) {
      //we cant include api name in axios.defaults.baseURL, cause we want to eval the host before we set it
      //set restApiName
      let apiLabel
      if (process.env.NODE_ENV == 'development') {
        //when npm run serve on localhost
        apiLabel = 'dev'
      } else if (window.location.host == 'booking-dev.webomaten.se') {
        //when deployed dev
        apiLabel = 'deployed_dev'
      } else {
        //when deployed prod
        apiLabel = 'prod'
      }
      const restApiName = 'booking-' + apiLabel
      let _path = '/' + restApiName + path
      return this.url_composeCachelessUrl(_path, queryStringData_ar, true)
    },
    url_composeCachelessUrl(address, queryStringData_ar = []) {
      return this.url_composeUrl(address, queryStringData_ar, true)
    },
    url_composeUrl(address, queryStringData_ar = [], cacheless) {
      if (cacheless) {
        queryStringData_ar.push({
          name: 'nocache',
          value: new Date().getTime()
        })
      }
      if (!queryStringData_ar) {
        return address
      }
      return this.url_appendQueryString(address, queryStringData_ar)
    },
    url_appendQueryString(address, queryStringData_ar) {
      if (!queryStringData_ar) {
        return address
      }
      let queryString = this.url_convertQueryStringDataArrayQueryString(queryStringData_ar)
      return address + '?' + queryString

    },
    url_convertQueryStringDataArrayQueryString(queryStringData_ar) {
      let queryString_ar = []
      for (let queryStringData of queryStringData_ar) {
        let item = queryStringData.name
//        if (this.hasProperty(queryStringData, 'value')) {
          item += '=' + queryStringData.value
//        }
        queryString_ar.push(item)
      }
      return queryString_ar.join('&')
    }
  }
}
