import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    firstRouterHasLoaded: false,
    agent: null,
  },
  mutations: {
    SET_FIRST_ROUTER_HAS_LOADED(state) {
      state.firstRouterHasLoaded = true
    },
    SET_AGENT(state, value) {
      state.agent = value
    },
  },
  getters: {
    agent: state => {
      return state.agent
    },
  },
  actions: {
    setFirstRouterHasLoaded({ commit }) {
      commit('SET_FIRST_ROUTER_HAS_LOADED', true)
    },
    setAgent({ commit }, value) {
      commit('SET_AGENT', value)
      //also set agent in local storage
      setLocalStorage('agent', value)
    },
  },
  modules: {
  }
})
//local storage functions
function setLocalStorage(key, value) {
  //ensure data as an JSON string
  let data = (typeof value === 'string') ? value : JSON.stringify(value)
  //store value in localStorage so it will be persistent over page reloads
  localStorage.setItem(key, data);
}
/*
function deleteLocalStorage(key) {
  //remove from local storage
  localStorage.removeItem(key)
}
*/