<template>
  <v-main>
    <template v-if="hasNoAgent">
      <div class="pa-8">
        <p class="text-h5 text-center">Hoppsan!</p>
        <p class="text-body-1 text-center">Din användare kunde inte identifieras.<br>Öppna sidan igen via ikonen i Kobra</p>
      </div>
    </template>
    <template v-else-if="viewDataIsLoaded">
      <v-app-bar
        fixed
        elevate-on-scroll
        dark
        color="primary"
      >
        <v-toolbar-title>Bokning</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="openAdminBooking"
          >
            Boka
          </v-btn>
        </v-toolbar-items>
      </v-app-bar>
      <v-container>
        <v-row class="pt-16">
          <v-col
          >
            <v-tabs
              v-model="tabMain"
            >
              <v-tab
                v-for="tab in tabs"
                :key="tab.label"
                :to="'#' + tab.label"
              >
                {{ tab.name }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabMain">
              <!--latestBookings-->
              <v-tab-item
                value="latestBookings"
              >
                <p class="text-body-2 pt-8">Nedan visas de 10 senaste bokningarna den senaste månaden</p>
                <v-simple-table
                  dense
                  class="xSimpleTable list xClick"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Lagd
                        </th>
                        <th class="text-left">
                          Namn
                        </th>
                        <th class="text-left">
                          Event
                        </th>
                        <th class="text-left">
                          Salong
                        </th>
                        <th class="text-left">
                          Bokad tid
                        </th>
                        <th class="text-right">
                          Personer
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="booking in latestBookings"
                        :key="booking.id"
                        @click="showBooking(booking)"
                        :class="{ 'alert' : booking.isCancelled }"
                      >
                        <td class="no-wrap">{{ booking.put }}</td>
                        <td>{{ booking.name }}</td>
                        <td>{{ booking.event }}</td>
                        <td>{{ booking.saloon }}</td>
                        <td>
                          <template v-if="booking.sessionType == 'slot'">
                            <span v-for="(time, index) in booking.times" :key="index">
                              <span class="no-wrap">
                                {{ time }}
                              </span>
                              <br>
                            </span>
                          </template>
                        </td>
                        <td class="text-right">{{ booking.clientCount }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div class="pt-4 text-body-2 text-center alert">
                  Röda = avbokade
                </div>
              </v-tab-item>
              <v-tab-item
                value="events"
              >
                <!--event-->
                <div class="d-flex mb-4">
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="goToEventGroupEdit()"
                        v-bind="attrs"
                        v-on="on"
                        small
                        class="ml-4"
                        color="primary"
                      >
                        <v-icon>mdi-plus</v-icon>
                        Grupp
                      </v-btn>
                    </template>
                    <span>Lägg till ny eventgrupp</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="goToEventEdit()"
                        v-bind="attrs"
                        v-on="on"
                        small
                        class="ml-4"
                        color="primary"
                        v-if="showButton_addEvent"
                      >
                        <v-icon>mdi-plus</v-icon>
                        Event
                      </v-btn>
                    </template>
                    <span>Lägg till nytt event</span>
                  </v-tooltip>
                </div>
                <v-simple-table
                  dense
                  class="xSimpleTable list xClick"
                >
                  <template
                    v-slot:default
                  >
                    <tbody
                      v-for="eventGroup in eventGroups"
                      :key="eventGroup.id"
                    >
                      <tr>
                        <th class="text-left" style="width:30px;">
                          <v-btn
                            icon
                            x-small
                            @click.stop="editEventGroup(eventGroup)"
                          >
                            <v-icon>
                              mdi-pencil
                            </v-icon>
                          </v-btn>
                        </th>
                        <th class="text-left">
                          {{ eventGroup.name }}
                        </th>
                        <th class="text-left">
                          Aktivt
                        </th>
                      </tr>
                      <tr
                        v-for="event in eventGroup.events"
                        :key="event.id"
                        @click="showEvent(event)"
                      >
                        <td class="text-left">
                          <v-btn
                            icon
                            x-small
                            @click.stop="editEvent(event)"
                          >
                            <v-icon>
                              mdi-pencil
                            </v-icon>
                          </v-btn>
                        </td>
                        <td>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ event.name }}
                              </span>
                            </template>
                            <span>Visa bokningar</span>
                          </v-tooltip>
                        </td>
                        <td>{{ event.visible }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>
              <v-tab-item
                value="saloons"
              >
                <!--saloons-->
                <div class="d-flex">
                  <v-spacer></v-spacer>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="goToSaloonEdit()"
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Lägg till ny salong</span>
                  </v-tooltip>
                </div>
                <v-simple-table
                  dense
                  class="xSimpleTable list xClick"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Namn
                        </th>
                        <th class="text-left">
                          Begränsad via
                        </th>
                        <th class="text-left">
                          Maxantal
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="saloon in saloons"
                        :key="saloon.id"
                        @click="editSaloon(saloon)"
                      >
                        <td>{{ saloon.name }}</td>
                        <td>{{ saloon.friendlyMultipleAppointmentType }}</td>
                        <td>{{ saloon.friendlyAppointmentLimit }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <!--snack-bar-->
    <app-snack-bar></app-snack-bar>
    <!--loader-->
    <v-overlay
      :value="loading"
      opacity="0.8"
      z-index="1001"
    >
      <v-progress-circular
        :size="60"
        color="secondary"
        indeterminate
      >
      </v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
import axios from 'axios'
import SnackBar from '../components/SnackBar'
import { snackBarBus } from '../main'
import { mapGetters } from 'vuex'
import { generalMixin } from '../mixins/general'
import { urlMixin } from '../mixins/url'
import { dateMixin } from '../mixins/date'

export default {
  name: 'Home',
  components: {
    'app-snack-bar': SnackBar,
  },
  mixins: [generalMixin, urlMixin, dateMixin],
  data: () => ({
    loading: false,
    hasNoAgent: null,
    viewDataIsLoaded: false,
    eventGroups: [],
    latestBookings: [],
    saloons: [],
    tabMain: null,
    agentPublic: null,
    tabs: [
      {
        label: 'latestBookings',
        name: 'Senaste bokningar'
      },
      {
        label: 'events',
        name: 'Event'
      }
      ,{
        label: 'saloons',
        name: 'Salonger'
      }
    ]
  }),
  computed: {
    ...mapGetters(['agent']),
    showButton_addEvent() {
      return this.eventGroups.length !== 0
    }
  },
  methods: {
    getData() {
      console.log('getData')
      if (this.loading) {
        return
      }
      this.loading = true
      let relUrl = '/admin/home/' + this.agent
      const url = this.url_composeApiUrl(relUrl)
      console.log('get', url)
      axios.get(url)
        .then(response => {
          console.log('response', response)
          this.loading = false
          if (response.data.success) {
            //success
            this.onData(response.data.returnData)
          } else if (this.onNonSuccessApiCall(response.data.returnData)) {
            snackBarBus.$emit('message', this.generalParseErrorArray(response.data.returnData.error_ar))
          }
        })
        .catch(error => {
          snackBarBus.$emit('message', error.toString())
          this.loading = false
        })
    },
    onData(data) {
      this.eventGroups = data.eventGroups
      this.latestBookings = data.latestBookings
      this.saloons = data.saloons
      this.viewDataIsLoaded = true
      this.agentPublic = data.agentPublic
    },
    showBooking(booking) {
      this.$router.push({ name: 'booking', params: { id: booking.id }, query: { referer: 'home' } })
    },
    showEvent(event) {
      this.$router.push({ name: 'event-bookings', params: { id: event.id } })
    },
    editEventGroup(eventGroup) {
      this.goToEventGroupEdit(eventGroup.id)
    },
    goToEventGroupEdit(id = 0) {
      this.$router.push({ name: 'event-group-edit', params: { id: id } })
    },
    editEvent(event) {
      this.goToEventEdit(event.id)
    },
    goToEventEdit(id = 0) {
      this.$router.push({ name: 'event-edit', params: { id: id } })
    },
    goToSaloonEdit(id = 0) {
      this.$router.push({ name: 'saloon-edit', params: { id: id } })
    },
    editSaloon(saloon) {
      this.goToSaloonEdit(saloon.id)
    },
    openAdminBooking() {
      window.open('http://booking.webomaten.se/?agent=' + this.agentPublic + '&agentPrivate=' + this.agent)
    }
  },
  created() {
    if (!this.agent) {
      this.hasNoAgent = true
    } else {
      this.getData()
    }
  }
}
</script>
